import $ from 'jquery';
import 'bootstrap';
import App from './modules/app';
import Carousel from './modules/carousel';
import { CookieConsent } from 'cookieconsent';

$(document).ready(function() {
    setTimeout(function() {
        $(".preload").fadeOut(200);
    }, 200)
});

    window.addEventListener('DOMContentLoaded', function(){
    App.run();
    Carousel.run();

 //    $(window).on("load", function() {
	//     setTimeout(function() {
	//     	$(".preload").fadeOut(300);
	//     }, 200)
	// });

    /****** Tease Pagination*****/
    const paginate = (element = $(".tease-wrapper")) => {
        let pages = element.length / 6;
        pages = pages % 1 === 0 ? pages : Math.floor(pages + 1);
        $('.pagination__list').html('');
        let i = 1;
        for (i = 1; i <= pages; i++) {
            const page = '<li class="list-inline-item pagination__item">\n' +
                '                                        <a href="" class="pagination__link">' + i + '</a>\n' +
                '                                        <span class=""></span>\n' +
                '                                </li>';
            $('.pagination__list').append(page);

        }
    }

    paginate();

    let allposts = $(".tease-wrapper");
    console.log(allposts);
    allposts.hide();
    allposts.slice(0, 6).show();

    $(document).on('click', '.pagination__link', function(e) {
        e.preventDefault();
        let posts = '';
        if ($('.category.active').length > 0 && $('.category.active')[0].innerHTML !== 'Tutto') {
            const cat = $('.category.active').text();
            console.log('load:', cat);
            posts = $('[data-category="' + cat + '"]').parent();
        } else {posts = allposts}
       const page = $(this).text();
       const offset = 6* (page - 1);
        posts.fadeOut(100);
        posts.slice(offset, (page*6)).fadeIn(100);
        let activePosts = posts.slice(offset, page*6+1);
        $('html,body').animate({
            scrollTop: $(this).offset().top
        }, 1500);
        console.log("page: ", page);
        console.log("offset: ", offset);
        console.log("Active Posts: ", activePosts);
    });

    $(".btn-load-more").on('click', function (e) {
        e.preventDefault();
        if ($('.category.active').length > 0 && $('.category.active')[0].innerHTML !== 'Tutto') {
            const cat = $('.category.active').text();

            console.log('load:', cat);

            $('[data-category="' + cat + '"]:hidden').parent().slice(0, 9).fadeIn(200);

            if ($('[data-category="' + cat + '"]:hidden').length == 0) {
                $(".btn-load-more").fadeOut('slow');
            }
        } else {
            $(".tease-wrapper:hidden").slice(0, 9).fadeIn(200);
            if ($(".tease-wrapper:hidden").length == 0) {
                $(".btn-load-more").fadeOut('slow');
            }
        }

        $('html,body').animate({
            scrollTop: $(this).offset().top
        }, 1500);
    });

    /****** Elements event handling*****/

    $('.category').on('click', function () {
        $('.category').removeClass('active');
        $(this).toggleClass('active');
        var cat = '';
        if ($(this).text().indexOf('#') === -1) {
            cat = $(this).text();
        } else {
            cat = $(this).text().substr($(this).text().indexOf('#') + 1, $(this).text().length)
        }
        console.log('cat: ', cat);
        if (cat !== 'Tutto') {
            // console.log('"' + cat + '"');
            $('[data-category]').parent().hide();
            const teases = $('[data-category="' + cat + '"]');
            console.log("Cat teases:", teases);
            paginate(teases.parent());
            teases.parent().hide()
            teases.parent().slice(0, 6).fadeIn(200);
            /************************/

        } else {
            paginate();
            $('[data-category]').parent().fadeOut(200);
            $(".tease-wrapper").slice(0,6).fadeIn(200);
            if ($('[data-category]:hidden').length == 0) {
                $(".btn-load-more").fadeOut('slow');
            } else {
                $(".btn-load-more:hidden").fadeIn('slow');
            }
        }

    });


        function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        let refs = $('.references--container__tile.only-8');
        refs.parent().html(shuffle(refs).splice(0, 8));

 });
