import $ from "jquery";
import 'slick-carousel';
// import Swiper from 'swiper';

class Carousel {

    run() {
        this.productCarousel();
        this.teamCarousel();
        // this.homePageCarousel();
    }

    productCarousel() {
    	if($(".carousel-1").length > 0 === true) {
    		$(".carousel-1").each(function() {
				$(this).slick({
		            dots: false,
		            infinite: false,
		            slidesToShow: 1,
		            slidesToScroll: 1,
		            speed: 1000,
		            cssEase: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
		            nextArrow: '<div class="next-slide"><svg width="62" height="16" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M59.7888 5.44457L61.9102 7.56595L59.7888 9.68727L54.4394 15.0366L52.3181 12.9153L56.1675 9.06592H0.5V6.06592H56.1675L52.3181 2.21654L54.4394 0.0952148L59.7888 5.44457Z" fill="#30323D"/></svg></div>',
		            prevArrow: '<div class="prev-slide"><svg width="62" height="16" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M59.7888 5.44457L61.9102 7.56595L59.7888 9.68727L54.4394 15.0366L52.3181 12.9153L56.1675 9.06592H0.5V6.06592H56.1675L52.3181 2.21654L54.4394 0.0952148L59.7888 5.44457Z" fill="#30323D"/></svg></div>',
		        });
    		});
    	}
    }

    teamCarousel() {

        //initialize swiper when document ready
        // var mySwiper = new Swiper('.carousel__swiper-team', {
        //     slidesPerView: 3,
        //     spaceBetween: 30,
        //     navigation: {
        //         nextEl: '.swiper-button-next',
        //     },
        //     // Responsive breakpoints
        //     breakpoints: {

        //         360: {
        //             slidesPerView: 1,
        //             spaceBetween: 20
        //         },

        //         768: {
        //             slidesPerView: 2,
        //             spaceBetween: 30
        //         },

        //         1280: {
        //             slidesPerView: 3,
        //             spaceBetween: 30
        //         }
        //     },
        // });

        if($(".carousel-3").length > 0 === true) {
    		$(".carousel-3").each(function() {
				$(this).slick({
		            dots: false,
		            infinite: false,
		            slidesToShow: 3,
		            slidesToScroll: 3,
		            speed: 1000,
		            cssEase: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
		            nextArrow: '<div class="next-slide"><svg width="62" height="16" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M59.7888 5.44457L61.9102 7.56595L59.7888 9.68727L54.4394 15.0366L52.3181 12.9153L56.1675 9.06592H0.5V6.06592H56.1675L52.3181 2.21654L54.4394 0.0952148L59.7888 5.44457Z" fill="#30323D"/></svg></div>',
		            prevArrow: '<div class="prev-slide"><svg width="62" height="16" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M59.7888 5.44457L61.9102 7.56595L59.7888 9.68727L54.4394 15.0366L52.3181 12.9153L56.1675 9.06592H0.5V6.06592H56.1675L52.3181 2.21654L54.4394 0.0952148L59.7888 5.44457Z" fill="#30323D"/></svg></div>',
		            responsive: [
					    {
					      breakpoint: 1024,
					      settings: {
					        slidesToShow: 2,
					        slidesToScroll: 1
					      }
					    },
					    {
					      breakpoint: 480,
					      settings: {
					        slidesToShow: 1,
					        slidesToScroll: 1
					      }
					    }
					    // You can unslick at a given breakpoint now by adding:
					    // settings: "unslick"
					    // instead of a settings object
				  	]
		        });
    		});
    	}

    }

    homePageCarousel() {
        var swiper = new Swiper('.swiper-container', {
            slidesPerView: 1.5,
            centeredSlides: true,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // Responsive breakpoints
            breakpoints: {

                360: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },

                450: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },


                1440: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },


                1920: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },

            },
        });
    }

}

const carousel = new Carousel();
export default carousel;