import $ from "jquery";
import {
    TweenMax,
    TimelineLite
} from 'gsap'
import Scrollbar from 'smooth-scrollbar';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollMagic from 'scrollmagic';
import LazyLoad from "vanilla-lazyload";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
const activated = [
    ScrollToPlugin
];

var lazyLoadInstance;

class App {

    run() {
        this.ctaBlock();
        this.scrollEffects();
        // this.dropdownService();
        this.menu();
        this.menuOpen();
        this.hoverEffects();
        this.servicesNumber();
        this.otherJs();
        this.cookies();
        this.lazy();
    }
    
    lazy() {
        lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy",
            threshold: 0
            // ... more custom settings?
        });
    }

    ctaBlock() {
        $(".cta").each(function() {
            const $this = $(this);
            const block = $this.find(".cta--text__title");
            const structure = block.html();
            const image = $(this).find(".cta--image .lazy");

            $this.find(".cta--image").prepend("<div class='cta--image__title'>" + structure + "</div>");

            $(window).on("load resize scroll", function() {

                image.one("load", function() {
                  // do stuff
                let leftV = block.offset().left;
                let topV = block.offset().top;
                let topimageV = $this.find(".cta--image").offset().top;
                let widthV = block.outerWidth();
                $this.find(".cta--image__title").css("left", leftV);
                $this.find(".cta--image__title").css("top", topV - topimageV);
                $this.find(".cta--image__title").css("width", widthV);
                
                }).each(function() {
                  if(this.complete) {
                      // $(this).load(); // For jQuery < 3.0 
                      $(this).trigger('load'); // For jQuery >= 3.0 
                  }
                });
            })
        });
    }

    scrollEffects() {

        // Custom smooth-scrollbar
        var scrollBarScene = Scrollbar.init(document.querySelector('.main-wrapper'), {
            refreshInterval: 0,
            renderByPixels: false,
            continuousScrolling: false,
            syncCallbacks: true,
        });

        scrollBarScene.addListener(function() {
            $('.red-underline').each(function () {
                if (scrollBarScene.isVisible(this) === true) {
                    $(this).addClass("active");
                }
            })
        })

        // ScrollTO btns

        $(".scroll-to-team").on("click", function() {
            const photosOffset = $("#team").offset().top - 150;
            scrollBarScene.scrollTo(0, photosOffset, 1200)
        })

        $(".scroll-to-metodo").on("click", function() {
            const photosOffset = $("#metodo").offset().top - 150;
            scrollBarScene.scrollTo(0, photosOffset, 1200)
        });

        $(".scroll-to-main-posts").on("click", function() {
            const photosOffset = $(".main-posts").offset().top - 150;
            console.log(photosOffset);
            scrollBarScene.scrollTo(0, photosOffset, 1200)
        });

        $(".scroll-to-homedescription").on("click", function() {
            const photosOffset = $("#homedescription").offset().top - 150;
            scrollBarScene.scrollTo(0, photosOffset, 4000)
        });

        $(".scroll-to-homedescription").on("click", function() {
            const photosOffset = $("#homedescription").offset().top - 150;
            scrollBarScene.scrollTo(0, photosOffset, 4000)
        });

        // Array for accoding various scrollmagic scenes
        let scenes = [];

        // init controller ScrollMagic
        const controller = new ScrollMagic.Controller({
            addIndicators: false
        });

        if($("#homeVideo").length > 0 === true) {
            // build scenes ScrollMagic
            const video = document.getElementById('homeVideo');
            const long = document.getElementById('homepage_bighero');
            // let scrollpos = 0;
            // let lastpos;

            var images = [
                "/wp-content/themes/sintesia/dist/img/sprite-home/out1.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out2.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out3.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out4.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out5.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out6.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out7.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out8.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out9.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out10.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out11.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out12.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out13.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out14.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out15.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out16.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out17.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out18.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out19.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out20.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out21.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out22.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out23.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out24.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out25.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out26.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out27.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out28.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out29.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out30.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out31.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out32.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out33.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out34.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out35.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out36.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out37.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out38.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out39.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out40.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out41.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out42.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out43.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out44.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out45.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out46.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out47.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out48.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out49.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out50.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out51.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out52.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out53.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out54.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out55.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out56.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out57.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out58.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out59.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out60.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out61.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out62.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out63.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out64.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out65.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out66.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out67.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out68.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out69.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out70.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out71.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out72.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out73.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out74.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out75.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out76.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out77.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out78.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out79.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out80.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out81.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out82.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out83.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out84.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out85.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out86.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out87.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out88.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out89.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out90.jpg",
                "/wp-content/themes/sintesia/dist/img/sprite-home/out91.jpg"
            ];

            let proof = ["/wp-content/themes/sintesia/dist/img/sprite-home/out"]

            const qty = images.length;
            let i;

            for (i = 1; i <= qty; i++) {
                $("#homeVideo").parent().append("<img class='image-disappears' style='display:none;' src='"+ proof + i +".jpg'>");
            };


            // $("#homeVideo").parent().append("<div style='background-image: url("+ images +");'></div>");

            var obj = {curImg: 0};

            // if($(window).width() < 1440 && $(window).width() > 800 === true) {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 200);
            // } else {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 500);
            // }

            // create tween
            const tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,  // animate propery curImg to number of images
                    roundProps: "curImg",               // only integers so it can be used as an array index
                    immediateRender: true,          // load first image automatically
                    ease: Linear.easeNone,          // show every image the same ammount of time
                    onUpdate: function () {
                      $("#homeVideo").attr("src", images[obj.curImg]); // set the image source
                    }
                }
            );

            const scene = new ScrollMagic.Scene({
                triggerElement: long,
                triggerHook: 0.06,
            }).setTween(tween).addTo(controller).duration(long.clientHeight);

            scenes.push(scene);
        }

        if($(".cartellaVideo").length > 0 === true) {

            let proof = ["/wp-content/themes/sintesia/dist/img/cartella/cartella"]

            var images = [
                "/wp-content/themes/sintesia/dist/img/cartella/cartella1.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella2.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella3.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella4.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella5.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella6.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella7.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella8.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella9.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella10.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella11.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella12.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella13.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella14.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella15.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella16.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella17.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella18.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella19.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella20.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella21.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella22.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella23.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella24.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella25.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella26.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella27.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella28.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella29.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella30.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella31.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella32.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella33.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella34.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella35.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella36.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella37.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella38.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella39.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella40.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella41.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella42.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella43.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella44.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella45.jpg",
                "/wp-content/themes/sintesia/dist/img/cartella/cartella46.jpg"
            ];

            let i;

            var obj = {curImg: 0};

            for (i = 1; i <= 46; i++) {
                $("#serviceVideo").parent().append("<img class='image-disappears' style='display:none;' src='"+ proof + i +".jpg'>");
            };

            // build scenes ScrollMagic
            const video = document.getElementById('serviceVideo');
            const long = document.getElementById('service_bighero');
            let scrollpos = 0;
            let lastpos;

            // if($(window).width() < 992 === true) {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 70);
            // }

            // create tween
            const tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,  // animate propery curImg to number of images
                    roundProps: "curImg",               // only integers so it can be used as an array index
                    immediateRender: true,          // load first image automatically
                    ease: Linear.easeNone,          // show every image the same ammount of time
                    onUpdate: function () {
                      $("#serviceVideo").attr("src", images[obj.curImg]); // set the image source
                    }
                }
            );

            const scene = new ScrollMagic.Scene({
                triggerElement: long,
                triggerHook: 0.06,
            }).setTween(tween).addTo(controller).duration(long.clientHeight / 1.8);

            scenes.push(scene);
        }

        if($(".puzzleVideo").length > 0 === true) {

            let proof = ["/wp-content/themes/sintesia/dist/img/puzzle/puzzle"]

            var images = [
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle1.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle2.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle3.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle4.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle5.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle6.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle7.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle8.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle9.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle10.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle11.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle12.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle13.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle14.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle15.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle16.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle17.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle18.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle19.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle20.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle21.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle22.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle23.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle24.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle25.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle26.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle27.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle28.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle29.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle30.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle31.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle32.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle33.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle34.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle35.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle36.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle37.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle38.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle39.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle40.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle41.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle42.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle43.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle44.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle45.jpg",
                "/wp-content/themes/sintesia/dist/img/puzzle/puzzle46.jpg"
            ];

            let i;

            var obj = {curImg: 0};

            for (i = 1; i <= 46; i++) {
                $("#serviceVideo").parent().append("<img class='image-disappears' style='display:none;' src='"+ proof + i +".jpg'>");
            };

            // build scenes ScrollMagic
            const video = document.getElementById('serviceVideo');
            const long = document.getElementById('service_bighero');
            let scrollpos = 0;
            let lastpos;

            // if($(window).width() < 992 === true) {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 70);
            // }

            // create tween
            const tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,  // animate propery curImg to number of images
                    roundProps: "curImg",               // only integers so it can be used as an array index
                    immediateRender: true,          // load first image automatically
                    ease: Linear.easeNone,          // show every image the same ammount of time
                    onUpdate: function () {
                      $("#serviceVideo").attr("src", images[obj.curImg]); // set the image source
                    }
                }
            );

            const scene = new ScrollMagic.Scene({
                triggerElement: long,
                triggerHook: 0.06,
            }).setTween(tween).addTo(controller).duration(long.clientHeight / 1.8);

            scenes.push(scene);
        }

        if($(".ipadVideo").length > 0 === true) {

            let proof = ["/wp-content/themes/sintesia/dist/img/ipad/ipad"]

            var images = [
                "/wp-content/themes/sintesia/dist/img/ipad/ipad1.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad2.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad3.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad4.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad5.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad6.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad7.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad8.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad9.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad10.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad11.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad12.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad13.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad14.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad15.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad16.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad17.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad18.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad19.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad20.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad21.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad22.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad23.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad24.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad25.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad26.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad27.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad28.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad29.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad30.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad31.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad32.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad33.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad34.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad35.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad36.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad37.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad38.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad39.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad40.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad41.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad42.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad43.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad44.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad45.jpg",
                "/wp-content/themes/sintesia/dist/img/ipad/ipad46.jpg"
            ];

            let i;

            var obj = {curImg: 0};

            for (i = 1; i <= 46; i++) {
                $("#serviceVideo").parent().append("<img class='image-disappears' style='display:none;' src='"+ proof + i +".jpg'>");
            };

            // build scenes ScrollMagic
            const video = document.getElementById('serviceVideo');
            const long = document.getElementById('service_bighero');
            let scrollpos = 0;
            let lastpos;

            // if($(window).width() < 992 === true) {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 70);
            // }

            // create tween
            const tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,  // animate propery curImg to number of images
                    roundProps: "curImg",               // only integers so it can be used as an array index
                    immediateRender: true,          // load first image automatically
                    ease: Linear.easeNone,          // show every image the same ammount of time
                    onUpdate: function () {
                      $("#serviceVideo").attr("src", images[obj.curImg]); // set the image source
                    }
                }
            );

            const scene = new ScrollMagic.Scene({
                triggerElement: long,
                triggerHook: 0.06,
            }).setTween(tween).addTo(controller).duration(long.clientHeight / 1.8);

            scenes.push(scene);
        }

        if($(".frecciaVideo").length > 0 === true) {

            let proof = ["/wp-content/themes/sintesia/dist/img/freccia/freccia"]

            var images = [
                "/wp-content/themes/sintesia/dist/img/freccia/freccia1.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia2.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia3.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia4.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia5.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia6.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia7.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia8.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia9.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia10.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia11.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia12.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia13.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia14.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia15.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia16.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia17.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia18.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia19.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia20.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia21.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia22.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia23.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia24.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia25.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia26.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia27.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia28.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia29.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia30.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia31.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia32.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia33.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia34.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia35.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia36.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia37.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia38.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia39.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia40.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia41.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia42.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia43.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia44.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia45.jpg",
                "/wp-content/themes/sintesia/dist/img/freccia/freccia46.jpg"
            ];

            let i;

            var obj = {curImg: 0};

            for (i = 1; i <= 46; i++) {
                $("#serviceVideo").parent().append("<img class='image-disappears' style='display:none;' src='"+ proof + i +".jpg'>");
            };

            // build scenes ScrollMagic
            const video = document.getElementById('serviceVideo');
            const long = document.getElementById('service_bighero');
            let scrollpos = 0;
            let lastpos;

            // if($(window).width() < 992 === true) {
            //     const heightBig = $(".bighero").outerHeight();
            //     $(".bighero").height(heightBig + 70);
            // }

            // create tween
            const tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,  // animate propery curImg to number of images
                    roundProps: "curImg",               // only integers so it can be used as an array index
                    immediateRender: true,          // load first image automatically
                    ease: Linear.easeNone,          // show every image the same ammount of time
                    onUpdate: function () {
                      $("#serviceVideo").attr("src", images[obj.curImg]); // set the image source
                    }
                }
            );

            const scene = new ScrollMagic.Scene({
                triggerElement: long,
                triggerHook: 0.06,
            }).setTween(tween).addTo(controller).duration(long.clientHeight / 2.3);

            

            scenes.push(scene);
        }

        const newsletterBtn = new ScrollMagic.Scene({
            triggerElement: "#footer",
            triggerHook: 1,
            }).setClassToggle("#newsletterSubscribe", "hide");

        //////////////////////////

        // Array of animation to call

        scenes.push(newsletterBtn);

        scenes.forEach(function(el) {
            controller.addScene(el);

            scrollBarScene.addListener(function() {
                el.refresh();
            });
        });

        // end of array

        // $(window).on("load", function() {
        //     $(".image-disappears").each(function() {
        //         $(this).detach();
        //     })
        // });


        //Add support for anchor linking between pages
        $(window).on("load", function() {
            if (window.location.href.indexOf('#') > 0) {
                lazyLoadInstance.loadAll();
                // scrollBarScene.scrollTo(0, top, 1200);
                setTimeout(() => {
                    const url = window.location.href;
                    const sectionBlock = url.substr(url.indexOf('#'), url.length);
                    const photosOffset = $(sectionBlock).offset().top - 150;
                    const top = parseInt(photosOffset);
                    scrollBarScene.scrollTo(0, top, 1200);
                }, 200);
                
                
            }
        })
        


    }

    menu() {

        if($("body[data-page]").length > 0 === true) {
            const name = $("body").attr("data-page");
            $(".header").addClass('menu-service');
            $(".nav__item--submenu-link[data-menu='"+name+"']").addClass("service-open");
        }

        $(".overlay-menu .nav .nav__link").each(function() {
            if($(this).parent().hasClass("menu-item-has-children") === true) {
                $(this).addClass('wsub')
            }
        });
        $(".overlay-menu .nav .nav__link.wsub").on("click", function() {
            if($(this).parent().hasClass("active") === true) {
                $(this).parent().removeClass("active");
                $(this).parent().find(".nav__item--submenu").height(0);
            } else {
                $(this).parent().addClass("active");
                const numb = $(this).parent().find(".nav__item--submenu-link").length;
                const heightV = $(this).parent().find(".nav__item--submenu-link").outerHeight();
                $(this).parent().find(".nav__item--submenu").height(heightV * numb);
            }
        });
        $(".navbar .nav .nav__link").each(function() {
            if($(this).parent().hasClass("menu-item-has-children") === true) {
                $(this).addClass('wsub')
            }
        });
        $(".navbar .nav .nav__link.wsub").on("click", function() {
            if($(this).parent().hasClass("active") === true) {
                $(this).parent().removeClass("active");
            } else {
                $(this).parent().addClass("active");
            }
        });
        $(".header").on("mouseleave", function() {
            $(".navbar .nav__item").removeClass('active');
        })
    }

    dropdownService() {
        let overlayMenu = $('.overlay-menu');
        // Handling click events for dropdowns servizi
        $('.servizi').children('a')
            .attr('type', 'button')
            .attr('disabled', 'disabled')
            .removeAttr('href');
        const dropdownBtn = "<span class='expand-dropdown'>+</span>";
        overlayMenu.find('.servizi').find('a').append(dropdownBtn);
        //overlayMenu.find('.servizi').append( '<ul class="list-unstyled dropdown-list"></ul>');

        var openedService = false;

        overlayMenu.find('.servizi').click(function() {
            if (openedService === true) {
                $(this).parent().find('.dropdown-list__item').fadeOut(100);
                openedService = false;
            } else {


                const children = JSON.parse($(this).children('a').attr('data-children'));
                // console.log(children);
                let items = '';
                for (child in children) {
                    items += "<li class='nav__item" + children[child].classes.join(' ') + " dropdown-list__item'><a class='nav__link dropdown-list__link' href='" + children[child].url + "'>" + children[child].name + "</a></li>";
                }
                let list = '<ul class="list-unstyled dropdown-list">' + items + '</ul>';
                // console.log(items);
                $(this).after(items);
                openedService = true;
            }
        });


        $('.navbar-wrapper').find('.servizi').click(function() {
            $('.navbar__drop-wrapper').fadeToggle(200);
            const children = JSON.parse($(this).children('a').attr('data-children'));
            console.log(children);
            let items = '';
            for (child in children) {
                items += "<li class='" + children[child].classes.join(' ') + "'><a href='" + children[child].url + "'>" + children[child].name + "</a></li>";
            }
            console.log(items);
            $('.dropdown').children('ul').html(items);
        });

    }

    menuOpen() {
        let overlayMenu = $('.overlay-menu');
        $('#menuToggler').click(() => {
            if(overlayMenu.hasClass("open") === false) {
                overlayMenu.addClass('open');
                var tl = new TimelineLite();
                tl.staggerTo(".overlay-menu .nav__item>a", 1, {
                    y: 0,
                    rotation: 0,
                    ease: Power3.easeOut,
                }, 0.1);
                $('.navbar-wrapper').addClass('menu-open');
                $('#menuToggler').addClass('x');
                $('#headerLogo').addClass('bright-7');
            } else {
                setTimeout(function() {
                overlayMenu.removeClass("open");
                },200)
                TweenLite.to(".overlay-menu .nav__item>a", 0.3, {
                  y: "80px",
                  rotation: 6,
                })
                $('.navbar-wrapper').removeClass('menu-open');
                $('#menuToggler').removeClass('x');
                $('#headerLogo').removeClass('bright-7');
            }
        });



        $('.overlay-menu .nav__item').hover(function() {
            $(this).addClass('hover');
        },
        function() {
            $(this).removeClass('hover');
        });
    }

    servicesNumber() {
        // $(".section-brand__tile").each(function(el) {
        //     el++;
        //     $(this).append("<div class='section-brand__number'>" + el + "</div>");
        // })
    }

    hoverEffects() {
    	$('.service-tile').hover(
		       function(){ $(this).addClass('hover') },
		       function(){ $(this).removeClass('hover') }
		)

        $('.tease-tile').hover(
               function(){ $(this).addClass('hover') },
               function(){ $(this).removeClass('hover') }
        )

        $(".hover-team").each(function() {
            const $this = $(this);
            const $paragraph = $this.find(".hidden-content");
            const $paragrapheight = $this.find(".hidden-content__description").height() * 1.67 + 102;
            // const $paragrapheight = $this.find(".hidden-content__description").outerHeight();
            $this.hover(
                   function(){
                       console.log('PAragraphHeight: ', $paragrapheight);

                       $this.addClass('hover');
                    const appear = TweenMax.to($paragraph, 0.4, {
                        height: $paragrapheight,
                        ease: "power3.out"
                    });
                },
                   function(){ $this.removeClass('hover');
                   const disappear = TweenMax.to($paragraph, 0.8, {
                        height: 0,
                        ease: "power3.out"
                    });
                    }
            )
        })

        $(".references--container__tile").each(function() {
            $(this).hover(
                function(){ $(this).addClass('hover') },
                function(){ $(this).removeClass('hover') }
            )
        })
            
    }

    otherJs() {
        // if($(".bighero .h4.red.text-uppercase").text() == "COMPETENZE, FIDUCIA E METODO") {
        //     $(".bighero .spacer").hide();
        //     $(".bighero").parent().find(".spacer").hide();
        //     $(".section-nav").append("<div class='spacer small'></div>");
        // }
        // if($(".bighero .h4.red.text-uppercase").text() == "Istruzioni di lavoro digitali, semplici e visual") {
        //     $(".bighero .spacer").hide();
        //     $(".bighero").parent().find(".spacer").hide();
        //     $(".section-nav").append("<div class='spacer small'></div>");
        // }
        // if($(".bighero .h4.red.text-uppercase").text() == "Connetti KanbanBOX a Power BI") {
        //     $(".bighero .spacer").hide();
        //     $(".bighero").parent().find(".spacer").hide();
        //     $(".section-nav").append("<div class='spacer small'></div>");
        // }

        $(".wpcf7-response-output").each(function() {
            $(this).wrap("<div class='wrap-message'></div>");
            let structure = $(this).parent().html();
            $(this).closest(".wpcf7-form").prepend(structure);
            $(this).parent().detach();
        });
        


    }

    cookies () {
        window.cookieconsent.initialise({
          "palette": {
            "popup": {
              "background": "#000000",
              "text": "#ffffff"
            },
            "button": {
              "background": "transparent",
              "border": "#ffffff",
              "text": "#ffffff"
            }
          },
          "content": {
            "message": "\nQuesto sito Web utilizza i cookie per assicurarti la migliore esperienza sul nostro sito Web.",
            "dismiss": "Accetto",
            "link": "Scopri di più",
            "href": "https://www.iubenda.com/privacy-policy/7969308"
          }
        });

        // $(".cc-window").each(function() {
        //     const dismiss = $(this).children(".cc-compliance").html();
        //     $(this).prepend(dismiss);
        //     $(this).children(".cc-dismiss").text("×").addClass("close-cookie").wrap("<div class='cc-compliance'></div>");
        // });
    }


}

const app = new App();
export default app;
